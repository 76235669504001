<template>
  <div class="filter-status">
    <div>
      <p class="filter-status__titles">
        <i class="el-icon-price-tag mr-1"></i>
        <span>Status</span>
      </p>
      <div class="container-checkbox">
        <el-checkbox
          v-for="item in filterInformation"
          :key="item.text"
          v-model="item.value"
          :disabled="item.disabled"
        >
          {{ item.text }}
        </el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterStatus",
  props: {
    filter: Array,
  },
  created() {
    localStorage.getItem("my-insurance-table", this.filterInformation);
  },
  data() {
    return {
      filterInformation: [
        {
          item: "Quoted",
          text: "Quoted",
          value: true,
        },
        {
          item: "Booked",
          text: "Booked",
          value: true,
        },
        {
          item: "Issued",
          text: "Issued",
          value: true,
        },
      ],
    };
  },
  watch: {
    filter: {
      immediate: true,
      handler() {
        if (this.filter) {
          this.filterInformation = this.filter;
        }
      }
    },
    filterInformation: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("getStatusInformation", this.filterInformation);
      },
    },
  },
  methods: {
    resetFilters() {
      // eslint-disable-next-line array-callback-return
      return this.filterInformation.map((el) => {
        el.value = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-status {
  background-color: #efefef;
  border-radius: 5px;
  padding: 4px;
  &__titles {
    display: flex;
    align-items: center;
    margin: 0px;
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
    span {
      width: 80%;
      text-align: start;
      margin-left: 3px;
    }
  }
  &__icons {
    width: 10px;
    height: 10px;
  }
}
.filter-status div {
  padding: 4px;
}

.container-checkbox {
  display: flex;
  flex-direction: column;
}

::v-deep .el-checkbox {
  margin-right: 0px;
  display: flex;
  align-items: center;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: $color-border-container;
  border-color: $color-border-container;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 11px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
.checkbox-group-1 {
  display: flex;
  flex-wrap: wraps;
}
</style>
