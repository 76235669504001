<template>
  <div class="container">
    <i class="ion ion-information-circle-outline" :id="target"></i>
    <b-popover :target="target" triggers="hover" placement="bottom">
      <template #title>{{ title }}</template>
      {{ content }}
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "Popover",
  props: {
    title: String,
    content: String,
    placement: {
      type: String,
      default: "top-start",
    },
    target: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: auto;
  padding: 0px;
}
::v-deep .ion-information-circle-outline{
  color: $color-secondary-button-text;
}
</style>
