import Dexie from "dexie";

let db;

function createInsuranceDb(payload) {
  db = new Dexie(payload.dbName);
  db.version(1).stores({
    certifyInsuranceData: "&insuranceId",
  });
  db.open();
}

function getFromDb(payload) {
  return new Promise((resolve) => {
    resolve(db[payload.collection].get(payload.id));
  });
}

function addToDb(payload) {
  return new Promise((resolve) => {
    resolve(db[payload.collection].add(payload.data));
  });
}

function updateDb(payload) {
  return new Promise((resolve) => {
    resolve(db[payload.collection].put(payload.data));
  });
}

function deleteFromDb(payload) {
  return new Promise((resolve) => {
    resolve(db[payload.collection].delete(payload.id));
  });
}

export default {
  createInsuranceDb,
  getFromDb,
  addToDb,
  updateDb,
  deleteFromDb,
};
