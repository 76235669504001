<template>
  <div class="insurance">
    <div class="insurance-container">
      <div class="insurance-container__load-name"></div>
      <div class="insurance-container__content">
        <b-tabs card no-fade>
          <div
            class="quoteinsurance-tab-container"
            v-for="(tab, index) in insuranceOptions"
            :key="index"
          >
            <b-tab :title="tab.title" :active="activeTab(tab)" @click="tabClickHandler(tab)" lazy>
              <b-overlay :show="loading" rounded="sm">
                <b-card-text>
                  <KeepAlive v-if="selectedComponent">
                    <component :is="selectedComponent"></component>
                  </KeepAlive>
                  <div v-else>
                    <router-view />
                  </div>
                </b-card-text>
                <template #overlay>
                  <div class="loading" v-show="loading">
                    <p class="loading__text">{{ $t("loading") }}...</p>
                  </div>
                </template>
              </b-overlay>
            </b-tab>
          </div>
        </b-tabs>
      </div>
      <div class="insurance-container__content"></div>
    </div>
  </div>
</template>

<script>
import MyInsurance from "./MyInsurance/Index.vue";
import QuoteInsurance from "./QuoteInsurance/Index.vue";
import db from "./dbInsurance";

export default {
  name: "Insurance",
  components: {
    MyInsurance,
    QuoteInsurance,
  },
  data() {
    return {
      insuranceOptions: [
        {
          title: "QUOTE INSURANCE",
          id: "quote-insurance",
          routeName: "QuoteInsurance",
          component: QuoteInsurance,
        },
        {
          title: "MY INSURANCE",
          id: "my-insurance",
          routeName: "MyInsurance",
          component: MyInsurance,
        },
      ],
      selectedComponent: null,
      loading: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === "MyInsurance") {
          this.selectedComponent = MyInsurance;
          return;
        }
        if (to.name === "QuoteInsurance") {
          this.selectedComponent = QuoteInsurance;
          return;
        }
        this.selectedComponent = null;
      },
    },
  },
  created() {
    db.createInsuranceDb({ dbName: "insuranceCertify" });
  },
  methods: {
    activeTab(tab) {
      return this.$route.path.includes(tab.id);
    },
    tabClickHandler(tab) {
      this.$router.push({ name: tab.routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance {
  padding: 1.5rem 15px;
}
.loading {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}
::v-deep .bg-light {
  background-color: $color-gray-light !important;
}

///****Tabs styles****\\\\
::v-deep {
  .card-header {
    background-color: $color-white;
    border-bottom: 0px;
    padding: 12px 9.8px;
  }

  .card-body {
    background: $color-gray-light;
    border: 1px solid $color-border-container;
    border-radius: 0px 10px 10px 10px;
    font-family: $font-family-portal;
  }
  .nav-tabs {
    &::-webkit-scrollbar {
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: $color-gray-dark;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-light;
      border: 2.1px solid $color-gray-dark;
      border-radius: 10px;
    }
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
  .nav-tabs .nav-link {
    background-color: $color-gray-dark;
    color: $color-white;
    font-family: $font-family-portal;
    font-weight: bold;
    @include font-small-information;
    border: 1px solid $color-border-container;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 15px 30px;
  }
  .nav-item a {
    font-weight: bold !important;
  }
  .nav-tabs .nav-link {
    min-width: 115px;
  }
  .nav-tabs .nav-link.active {
    color: $color-primary-company;
    font-family: $font-family-portal;
    font-weight: bold;
    @include font-small-information;
    background-color: $color-gray-light;
    border: 1px solid $color-border-container;
    border-bottom: 1px solid $color-gray-light;
    padding: 15px 30px;
  }

  .nav-tabs > li {
    &:first-child > a {
      border-top-left-radius: 20px;
    }
    &:last-child > a {
      border-top-right-radius: 20px;
    }
  }
}
</style>
