<template>
  <div class="my-insurance">
    <div class="my-insurance-header">
      <SearchInsurance
        @insuranceSearch="insuranceSearch"
        @insuranceClearSearchField="insuranceClearSearchField"
      />
      <div class="filters-container">
        <AddColumnsGrid :type="'insurance'" @addColumns="columnsData = $event"></AddColumnsGrid>
        <i v-b-popover.hover.top="'Filters'">
          <button class="button-container" @click="openFilters()">
            <el-badge :value="numberFilters">
              <img src="@/assets/scss/icons/filter.svg" alt="filter-icon" />
            </el-badge>
          </button>
        </i>
        <ExportTableButton
          v-b-popover.hover.top="'Download excel'"
          :filters="filters"
          :filteredData="filteredData"
          @getInsurancesList="getInsurancesList"
          @handlingLimitItemsTable="handlingLimitItemsTable"
          @validateStatusParam="validateStatusParam"
        />
      </div>
      <div class="filters-float-container" v-if="showFilters">
        <FilterInsuranceTable
          :savedFilters="savedFilters"
          @selectedFilters="selectedFilters"
          @resetFilters="resetFilters"
          @closeFilters="closeFilters"
        />
      </div>
    </div>
    <InsuranceTable
      @loadMoreItems="loadMoreItems"
      @getInsurancesList="getInsurancesList"
      :columnsData="columnsData"
      :tableData="filteredData"
      :updating="updating"
      :loading="loading"
      :allDataHasBeenLoaded="allDataHasBeenLoaded"
    />
  </div>
</template>

<script>
import ExportTableButton from "./components/ExportTableButton.vue";
import SearchInsurance from "./components/SearchInsurance.vue";
import InsuranceTable from "./components/InsuranceTable.vue";
import FilterInsuranceTable from "./components/FilterInsuranceTable.vue";
import AddColumnsGrid from "../../../components/AddColumnsGrid.vue";

export default {
  name: "MyInsurance",
  components: {
    InsuranceTable,
    FilterInsuranceTable,
    SearchInsurance,
    ExportTableButton,
    AddColumnsGrid,
  },
  data() {
    return {
      showFilters: false,
      filteredData: [],
      filters: null,
      skip: 0,
      limit: 25,
      updating: false,
      allDataHasBeenLoaded: false,
      savedFilters: null,
      numberFilters: 0,
      loading: false,
      certificateDocument: null,
      shareDocumentModal: false,
      certificateModal: false,
      columnsData: "",
    };
  },
  async created() {
    this.getFilterFromLocalStorage();
    if (this.savedFilters) {
      const params = this.buildSchemaToFilterParams(this.savedFilters);
      this.filters = params;
      this.filteredData = await this.getInsurancesList(params);
      return;
    }
    this.filteredData = await this.getInsurancesList();
  },
  methods: {
    async getInsurancesList(params, disabledLoad) {
      // if (this.allDataHasBeenLoaded) {
      //   return [];
      // }
      if (!disabledLoad) this.loading = true;
      const response = await this.$store.dispatch("insurance/getAllInsurances", {
        skip: this.skip,
        limit: this.limit,
        ...(params || {}),
      });
      if (!response.length || response.length < this.limit) {
        this.allDataHasBeenLoaded = true;
      }
      if (!disabledLoad) this.loading = false;
      return response;
    },
    async loadMoreItems() {
      this.updating = true;
      this.skip += this.limit;
      const params = this.buildSchemaToFilterParams(this.savedFilters);
      const moreInsurances = await this.getInsurancesList(params);
      this.filteredData = [...this.filteredData, ...moreInsurances];
      this.updating = false;
    },
    async selectedFilters(data) {
      this.allDataHasBeenLoaded = false;
      this.handlingLimitItemsTable({ skip: 0, limit: 25 });
      this.getFilterFromLocalStorage();
      const params = this.buildSchemaToFilterParams(data);
      this.filters = params;
      this.filteredData = await this.getInsurancesList(params);
    },
    buildSchemaToFilterParams(data) {
      const params = {};
      const statusParam = this.validateStatusParam(data);
      if (data && statusParam.length > 0) {
        params.status = statusParam;
      }
      if (data && data.conveyance) {
        params.conveyance = data.conveyance;
      }
      if (data && data.stops.origin) {
        params.origin = data.stops.origin;
      }
      if (data && data.stops.destination) {
        params.destination = data.stops.destination;
      }
      return params;
    },
    validateStatusParam(data) {
      const statusItems = data?.status || [];
      const checkedItems = statusItems.filter((item) => item.value);
      const statusArray = checkedItems.map((item) => item.text);
      return statusArray;
    },
    openFilters() {
      this.showFilters = true;
    },
    closeFilters() {
      this.showFilters = false;
    },
    resetFilters() {
      this.numberFilters = 0;
      this.savedFilters = {};
      this.filters = {};
      this.allDataHasBeenLoaded = false;
    },
    async insuranceSearch(search) {
      this.loading = true;
      this.filteredData = [];
      this.skip = 0;
      this.filteredData = await this.$store.dispatch("insurance/searchInsurance", {
        search,
        skip: this.skip,
        limit: this.limit,
      });
      this.loading = false;
    },
    async insuranceClearSearchField() {
      this.loading = true;
      this.filteredData = this.$store.getters["insurance/getInsurances"];
      if (this.filteredData.length === 0) {
        this.filteredData = await this.getInsurancesList();
      }
      this.loading = false;
    },
    badgeFiltersQuantityHandler() {
      let counter = 0;
      if (this.savedFilters?.conveyance) counter += 1;
      const validateStatus = this.validateStatusParam(this.savedFilters);
      if (validateStatus.length > 0 && validateStatus.length < 3) counter += 1;
      if (this.savedFilters?.stops.origin) counter += 1;
      if (this.savedFilters?.stops.destination) counter += 1;
      this.numberFilters = counter;
    },
    getFilterFromLocalStorage() {
      const infoLocalStorage = JSON.parse(localStorage.getItem("my-insurance-table"));
      if (infoLocalStorage) {
        if (infoLocalStorage) {
          // eslint-disable-next-line prefer-object-spread
          this.savedFilters = Object.assign({}, this.savedFilters, infoLocalStorage);
        }
      }
      this.badgeFiltersQuantityHandler();
    },
    handlingLimitItemsTable({ skip, limit }) {
      this.limit = limit;
      this.skip = skip;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-insurance-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  margin-bottom: 15px;
  padding-bottom: 1px;
  position: relative;
  @media (max-width: 700px) {
    margin-top: 10px;
  }
}
.filters-container {
  display: flex;
  gap: 6px;
  height: max-content;
}
.button-container {
  border-radius: 5px;
  border: 2px solid $color-border-container;
  color: $color-primary-company;
  background-color: #efefef;
  padding: 0.1rem 0.4rem;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    border-color: $color-border-container;
    background-color: rgba(0, 185, 204, 0.0588235294);
  }
}
.filters-float-container {
  position: absolute;
  top: 41px;
  right: 45px;
  z-index: 99;
  border: 1px solid $color-border-container;
  border-radius: 10px;
  padding: 5px;
  background-color: $color-white;
}
::v-deep .el-badge__content,
.el-progress.is-exception .el-progress-bar__inner {
  background-color: $color-border-container;
}
::v-deep .el-badge__content.is-fixed {
  display: flex;
  align-items: center;
  z-index: 10;
  font-size: 11px;
}
::v-deep .el-loading-mask {
  background-color: transparent;
  @include loading-overlay();
}
::v-deep .container-columns {
  top: 5px !important;
}
</style>
