<template>
  <div class="insurance-document-viewer" @click.stop>
    <div class="go-back-icon" @click.stop="hiddeViewer()">
      <i class="ion ion-close-circle-outline"></i>
    </div>
    <div class="paginator col-12 d-flex justify-content-center mb-3">
      <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
        <b-input-group>
          <div class="paginator-content d-flex">
            <b-input-group-prepend>
              <b-button @click.stop="previous()" :class="previousLogic">
                <i class="ion ion-chevron-back-outline"></i>
              </b-button>
            </b-input-group-prepend>
            <input type="number" v-model="page" :max="totalPage" />
            <b-input-group-append>
              <b-input-group-text variant="outline-light">/ {{ totalPage }}</b-input-group-text>
              <b-button @click.stop="next()" :class="nextLogic">
                <i class="ion ion-chevron-forward-outline"></i>
              </b-button>
            </b-input-group-append>
          </div>
          <b-input-group-append>
            <button class="button-action" @click.stop="downloadDoc()">
              <i class="ion ion-cloud-download-outline"></i>Download
            </button>
          </b-input-group-append>
          <b-input-group-append>
            <button class="button-action" @click.stop="shareDocument()">
              <i class="ion ion-mail-outline"></i>Share
            </button>
          </b-input-group-append>
        </b-input-group>
      </b-button-toolbar>
    </div>
    <div class="document-container">
      <pdf
        v-if="document && !isImageDocument"
        :src="document.url"
        @num-pages="totalPage = $event"
        @page-loaded="currentPage = $event"
        :page="page"
      />
      <img v-if="document && isImageDocument" :src="document.url" alt="Image document" />
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import downloadDocument from "@/utils/DownloadDocuments";

export default {
  name: "InsuranceDocumentViewer",
  components: {
    pdf,
  },
  props: {
    document: Object,
    insuranceId: String,
  },
  data() {
    return {
      page: 1,
      totalPage: 0,
      numPage: 0,
      currentPage: 0,
      imagesExtension: [".png", ".jpg", ".jpeg", ".tiff"],
    };
  },
  computed: {
    isImageDocument() {
      return this.imagesExtension.some((extension) => this.document.url.includes(extension));
    },
    previousLogic() {
      if (this.page === 1) {
        return "disabled";
      }
      return "enabled";
    },
    nextLogic() {
      if (this.page === this.totalPage) {
        return "disabled";
      }
      return "enabled";
    },
  },
  methods: {
    downloadDoc() {
      downloadDocument(this.document.url, this.document.name);
    },
    hiddeViewer() {
      this.$emit("handlerDocumentViewer", false);
      this.$emit("enableScroll");
    },
    previous() {
      this.page -= 1;
    },
    next() {
      this.page += 1;
    },
    shareDocument() {
      this.hiddeViewer();
      this.$emit("shareDocument", this.document);
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-document-viewer {
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 2.5%;
  right: 0;
  top: 0;
  margin: 0px;
  border-radius: 0px;
  overflow: auto;
  z-index: 999;
  background: rgba($color: #000000, $alpha: 0.4);
}
.buttons-container {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  z-index: 99;
  margin: 10px;
  &__button {
    width: 60px;
    height: 30px;
    border-radius: 7px;
    margin-left: 5px;
    background: $color-white;
    color: $color-primary-company;
    border: 1px solid $color-border-container;
    &:hover {
      color: $color-white;
      background: $color-primary-company;
      transition: 0.2s all;
      border: $color-white;
    }
  }
}
.go-back-icon {
  position: absolute;
  right: 20px;
  color: white;
  cursor: pointer;
  z-index: 999;
  i {
    font-size: 30px;
    font-weight: bold;
  }
}
.button-action {
  display: flex;
  align-items: center;
  border-left: 1px solid rgb(207, 207, 207);
  color: $color-border-container;
  @include font-small-button-text;
  font-weight: 400;
  padding: 8px;
  padding-right: 10px;
  i {
    margin-right: 5px;
    font-size: 15px;
  }
}
/* components custom styles */
.disabled {
  pointer-events: none;
}
.paginator {
  text-align: center;
  &-content {
    font-size: 1rem;
  }
  .input-group {
    justify-content: center;
  }
}
input {
  width: 45px;
  outline: none;
  border: none;
  text-align: center;
  pointer-events: none;
  color: $color-primary-company;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
button {
  background: $color-white;
  color: $color-primary-company;
  border: none;
}
button:hover {
  background: $color-primary-company;
  color: $color-white;
}
</style>
