<template>
  <div class="send-certificate" v-loading="loading">
    <div class="input-container">
      <span class="input-container__text">Certificate Number</span>
      <RCInput
        class="input-container__input"
        :class="emptyFields['certificateNumber'] === true ? 'missing-field' : ''"
        type="text"
        v-model="formData.certificateNumber"
      />
    </div>
    <span class="error-message" v-if="emptyFields['files']">You must include at one document*</span>
    <div class="file-container">
      <DragAndDropDecoupled
        v-model="formData.files"
        :isCargoCare="true"
        :allowedExtensions="['pdf']"
      />
    </div>
    <div class="button-container" @click="sendCertificate()">Save</div>
  </div>
</template>

<script>
import RCInput from "rcat-components/RCInput";
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import DragAndDropDecoupled from "../../../../components/DragAndDropDecoupled.vue";

export default {
  name: "SendCertificate",
  components: {
    RCInput,
    DragAndDropDecoupled,
  },
  props: {
    insuranceId: String,
    document: Object,
  },
  data() {
    return {
      formData: {
        certificateNumber: 0,
        files: null,
      },
      emptyFields: {
        certificateNumber: false,
        files: false,
      },
      loading: false,
    };
  },
  methods: {
    validateFields() {
      this.resetEmptyFields();
      const formValidation = ValidateEmptyFields(this.formData);
      formValidation.emptyFields.forEach((key) => {
        this.emptyFields[key] = true;
      });
      if (Object.keys(this.formData.files).length === 0) {
        this.emptyFields.files = true;
      }
      return formValidation.validation;
    },
    async sendCertificate() {
      if (!this.formData.files) {
        this.swal({
          title: "Error",
          text: "You must attach a valid file",
          icon: "warning",
        });
        return;
      }
      const validation = this.validateFields();
      if (validation) {
        this.loading = true;
        await this.updateCertificate();
        const bucketInfo = await this.generateFileUrl();
        await this.uploadFileToBucket(bucketInfo.url);
        this.loading = false;
        this.$emit("handlerSendDocumentModal", false);
        this.$store.commit("insurance/reRenderTable");
      }
    },
    resetEmptyFields() {
      Object.keys(this.emptyFields).forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    async updateCertificate() {
      const response = await this.$store.dispatch("insurance/updateCertificateNumber", {
        id: this.insuranceId,
        certificadeId: this.formData.certificateNumber,
      });
      this.$store.commit("insurance/updateInsurance", { id: this.insuranceId, body: response });
    },
    async generateFileUrl() {
      const response = await this.$store.dispatch("insurance/generateUploadSignedUrl", {
        id: this.insuranceId,
        fileName: `certificate/${this.formData.certificateNumber}.pdf`,
      });
      return response;
    },
    async uploadFileToBucket(url) {
      const body = {
        file: this.formData.files.raw
      };
      this.$store.dispatch("insurance/updateFileToBucket", { url, formData: body });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  margin: 15px auto;
  &__text {
    color: $color-primary-company;
    font-family: $font-family-portal;
  }
  &__input {
    width: 100%;
  }
}
.location-selector {
  display: flex;
  gap: 10px;
  &__country {
    max-width: 70px;
    text-align: left;
  }
  &__location {
    width: 100%;
    text-align: left;
  }
}
.button-container {
  @include primary-button;
  @include font-button-text;
  width: 50%;
  font-weight: 400;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.error-message {
  color: red;
  font-size: 13px;
}
.file-list {
  &__file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    i {
      color: #f40f02;
      margin-right: 8px;
    }
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: start;
      margin: 10px 0px;
      color: $color-primary-company;
    }
  }
  &__button {
    background: none;
    border: none;
    i {
      color: $color-primary-company !important;
    }
  }
}
::v-deep .el-upload-dragger {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .rc-form-group {
  width: 100%;
}
::v-deep .rc-input-container {
  border: 1px solid $color-border-container;
}
::v-deep .missing-field .rc-input-field {
  background-color: $missing-fields;
}

::v-deep {
  .el-upload {
    display: flex;
    @media (max-width: 800px) {
      justify-content: flex-end;
    }
  }
  .el-upload__text {
    color: $color-primary-company;
    em {
      color: $color-border-container;
      font-weight: 600;
    }
  }
  .el-upload-dragger {
    border-radius: 10px;
    height: 100px;
    width: 100%;
    border: 1px dashed $color-border-container;
  }
  .el-icon-document:before {
    font-size: 40px;
    color: $color-border-container;
  }
  .el-icon-document {
    margin: 10px 0;
  }
  .el-icon-close:before {
    color: $color-primary-company;
    font-weight: 600;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  .el-upload-list__item {
    display: none;
  }
  .upload-demo {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  .el-button {
    margin: 3px 5px 2px 0px;
    background-color: $color-primary-company;
    color: $color-white;
    border-radius: 8px;
    border: none;
    padding: 4px;
    @include font-small-button-text;
    font-weight: 400;
    &:hover {
      background-color: $color-primary-company-lighten;
    }
  }
  .el-upload-list {
    margin: 0px 5px 2px 5px;
  }
  .el-upload-list__item:first-child {
    margin-top: 5px;
  }
  .el-upload-list__item-name {
    color: $color-primary-company;
    padding-left: 12px;
  }
}

::v-deep .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  box-shadow: none;
}

::v-deep .p-button.p-button-icon-only {
  padding: 3px;
  width: 1.2rem;
  font-size: 8px;
  background-color: $color-border-container;
  border: none;
}
</style>
