<template>
  <div class="filters-container" v-click-outside="hide">
    <div class="filter-insurance">
      <div class="filter-insurance__left">
        <FilterLocation
          :filter="savedFilters?.stops"
          @getLocationInformation="getLocationInformation"
          ref="location-filter"
          class="filter-location"
        />
        <div class="conveyance-container">
          <span class="covenyance-label" :class="conveyanceInformation ? 'active-filter' : ''">
            <i class="ion ion-bag-outline"></i>
            Convenyance
          </span>
          <ConveyanceSelector
            :propValue="savedFilters?.conveyance"
            :usedInFilterMenu="true"
            :isFilterInstance="true"
            @getConveyanceInformation="getConveyanceInformation"
            ref="conveyance-filter"
            class="conveyance-selector"
          />
        </div>
      </div>
      <FilterStatus
        :filter="savedFilters?.status"
        @getStatusInformation="getStatusInformation"
        ref="status-filter"
      />
    </div>
    <div class="button-container">
      <button class="button-container__button" @click="applyFilters()">Apply</button>
      <button class="button-container__button" @click="resetFilters()">Reset</button>
      <button class="button-container__button" @click="$emit('closeFilters')">Close</button>
    </div>
  </div>
</template>

<script>
// import ConveyanceSelector from "./ConveyanceSelector.vue";
import ConveyanceSelector from "../../components/ConveyanceComponent.vue";
import ClickOutside from "../../../../directives/ClickOutside";
import FilterLocation from "./FilterLocation.vue";
import FilterStatus from "./FilterStatus.vue";

export default {
  name: "FilterInsurance",
  props: {
    savedFilters: Object,
  },
  components: {
    FilterLocation,
    FilterStatus,
    ConveyanceSelector,
  },
  data() {
    return {
      triggerOpenedFilters: true,
      stopsInformation: {},
      statusInformation: [],
      conveyanceInformation: "",
    };
  },
  computed: {
    filters() {
      return {
        stops: this.stopsInformation,
        conveyance: this.conveyanceInformation,
        status: this.statusInformation,
      };
    },
  },
  directives: {
    "click-outside": ClickOutside,
  },
  methods: {
    hide() {
      if (this.triggerOpenedFilters) {
        this.triggerOpenedFilters = false;
        return;
      }
      this.$emit("closeFilters");
    },
    applyFilters() {
      localStorage.removeItem("my-insurance-table");
      const storageInsuranceTable = this.filters;
      localStorage.setItem("my-insurance-table", JSON.stringify(storageInsuranceTable));
      // this.filters.status = this.validateStatusParam(this.filters);
      this.$emit("selectedFilters", this.filters);
      this.hide();
    },
    validateStatusParam(data) {
      const statusItems = data?.status || [];
      const checkedItems = statusItems.filter((item) => item.value);
      const statusArray = checkedItems.map((item) => item.text);
      return statusArray;
    },
    resetFilters() {
      localStorage.removeItem("my-insurance-table");
      this.$emit("resetFilters");
      this.$refs["location-filter"].resetFilters();
      this.$refs["conveyance-filter"].resetFilters();
      this.$refs["status-filter"].resetFilters();
      // need to the style label of conveyance title in filters menu
      this.conveyanceInformation = "";
    },
    getStatusInformation(data) {
      this.statusInformation = data;
    },
    getLocationInformation(value) {
      this.stopsInformation = value;
    },
    getConveyanceInformation(value) {
      this.conveyanceInformation = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-container {
  display: flex;
  flex-direction: column;
}
.filter-insurance {
  min-width: 300px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  &__left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.filter-location {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 4px;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  &__button {
    border-radius: 15px;
    border: 1px solid $color-border-container;
    background-color: #fff;
    color: $color-border-container;
    margin: 2px;
    font-weight: bold;
    height: 25px;
    width: 70px;
    font-size: 11px;
  }
}
.conveyance-container {
  background-color: #efefef;
}
.covenyance-label {
  display: flex;
  align-items: center;
  color: #606266;
  font-size: 11px;
  margin-left: 8px;
  i {
    font-size: 13px;
    margin-right: 5px;
  }
}
.active-filter {
  color: $color-primary-trigger-button !important;
}
::v-deep .conveyance-container {
  svg {
    fill: #606266;
  }
  .conveyance-selector {
    display: flex;
    flex-direction: column;
    gap: 0px !important;
  }
  .button-container {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    min-width: 78px;
    border-radius: 23px;
    border: none;
    background-color: white;
    border: 1px solid #dcdfe6;
    padding: 6px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 16px;
      width: 16px;
    }
    span {
      color: #606266;
      padding-left: 0px;
    }
  }
  .ground-rail-btn {
    min-width: 150px;
    span {
      width: 100px;
    }
  }
  .button-container:hover {
    background-color: $color-primary-trigger-button !important;
    transition: 0.3s ease all;
    & > span {
      color: white !important;
    }
    & > .svg-icon {
      fill: white !important;
    }
  }
  .active {
    background-color: $color-primary-trigger-button !important;
    transition: 0.3s ease all;
    & > span {
      color: white !important;
    }
    & > .svg-icon {
      fill: white !important;
    }
  }
}
</style>
