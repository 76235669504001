<template>
  <div class="insurance-table">
    <el-table
      :data="tableData"
      :height="tableHeight"
      v-loading="loading"
      :key="renderKeyTable"
      @row-click="rowSelected"
    >
      <template v-slot:empty>
        <div class="insurance-not-found">
          <i class="ion ion-file-tray-outline insurance-not-found__icon"></i>
          <p>No insurances were found.</p>
        </div>
      </template>
      <el-table-column
        v-for="(column, index) in columnFields"
        :key="index"
        :label="column.label"
        :prop="column.field"
        :min-width="column.minWidth"
      >
        <template slot-scope="scope">
          {{ scope.row[column.field] }}
          <div v-if="column.field === 'shp'">
            {{ scope.row.load?.shp }}
          </div>
          <div v-if="column.field === 'insuranceStatus'">
            <span :class="statusColorMap[scope.row.status]">{{ scope.row.status }}</span>
          </div>
          <div v-if="column.field === 'origin'">
            {{
              formatLocationText(
                scope.row.origin_city,
                scope.row.origin_state,
                scope.row.origin_zip_code
              )
            }}
          </div>
          <div v-if="column.field === 'destination'">
            {{
              formatLocationText(
                scope.row.destination_city,
                scope.row.destination_state,
                scope.row.destination_zip_code
              )
            }}
          </div>
          <div v-if="column.field === 'date'">
            {{ scope.row.sailing_date | dateFormat }}
          </div>
          <div v-if="column.field === 'insurance_value'">
            $ {{ scope.row.insured_value | insurancePrice }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :min-width="100" align="center" label="Tools">
        <template slot-scope="scope">
          <ToolsInsuranceTable :scope="scope.row" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnResize from "@/Extend/onResize";
import ToolsInsuranceTable from "./ToolsInsuranceTable.vue";

export default {
  name: "InsuranceTable",
  mixins: [OnResize],
  components: {
    ToolsInsuranceTable,
  },
  props: {
    tableData: Array,
    allDataHasBeenLoaded: Boolean,
    updating: Boolean,
    loading: Boolean,
    columnsData: String,
  },
  data() {
    return {
      columnFieldsDefault: [
        {
          label: "Quote Number",
          field: "shp",
          minWidth: 100,
          enableCopy: true,
        },
        {
          label: "Conveyance",
          field: "conveyance",
          minWidth: 86,
          enableCopy: true,
        },
        {
          label: "Status",
          field: "insuranceStatus",
          minWidth: 100,
          enableCopy: true,
        },
        {
          label: "Origin",
          field: "origin",
          minWidth: 170,
          enableCopy: true,
        },
        {
          label: "Destination",
          field: "destination",
          minWidth: 170,
          enableCopy: true,
        },
        {
          label: "Commodity",
          field: "commodity",
          enableCopy: true,
          minWidth: 180,
        },
        {
          label: "Certificate Number",
          field: "certificate_id",
          minWidth: 150,
          enableCopy: true,
        },
        {
          label: "Date of Departure",
          field: "date",
          minWidth: 100,
          enableCopy: true,
        },
        {
          label: "Insurance Value",
          field: "insurance_value",
          enableCopy: true,
        },
      ],
      addColumns: {
        marks_or_numbers: {
          label: "Mark or Numbers",
          field: "marks_or_numbers",
          enableCopy: true,
        },
        reference_number: {
          label: "Number Reference",
          field: "reference_number",
          enableCopy: true,
        },
      },
      columnFields: [],
      statusColorMap: {
        Quoted: "quoted-color",
        Booked: "booked-color",
        Issued: "issued-color",
      },
      tableHeight: null,
      tableContainer: null,
      tableContainerHeight: null,
      rowsContainer: null,
    };
  },
  computed: {
    ...mapGetters({
      renderKeyTable: "insurance/getRenderKeyTable",
    }),
  },
  async mounted() {
    await this.sleep(0);
    this.tableContainer = document.querySelector(".el-table__body-wrapper");
    this.tableContainerHeight = this.tableContainer.clientHeight;
    this.tableContainer.addEventListener("scroll", this.infiniteScrollHandler);
    this.rowsContainer = document.querySelector(".el-table__body");
    this.columnFields = this.columnFieldsDefault;
  },
  watch: {
    screenWidth() {
      const clientHeight = window.innerHeight;
      this.tableHeight = clientHeight - 300;
    },
    columnsData() {
      this.changeColumnData(this.columnsData);
    },
  },
  methods: {
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    rowSelected(row) {
      if (row.is_certificate) {
        this.$router.push({
          name: "InsuranceDetails",
          params: {
            id: row.id,
          },
        });
        return;
      }
      this.$router.push({
        name: "InsuranceSelection",
        params: {
          id: row.id,
        },
      });
    },
    infiniteScrollHandler() {
      if (this.allDataHasBeenLoaded || this.updating) {
        return;
      }
      const elementTableHeight = this.rowsContainer.clientHeight;
      const currentScrollPosition = this.tableContainer.scrollTop;
      if (currentScrollPosition + this.tableContainerHeight < elementTableHeight - 20) {
        return;
      }
      this.tableContainer.scrollTop = currentScrollPosition - 30;
      this.$emit("loadMoreItems");
    },
    formatLocationText(city, state, zipcode) {
      return `${city}, ${state} ${zipcode}`;
    },
    changeColumnData(columns) {
      this.columnFields = [];
      this.columnFields = this.columnFieldsDefault;
      const arrayColumns = columns.split(",");
      const newColumns = arrayColumns
        .filter((column) => column !== "")
        .map((column) => this.addColumns[column]);
      this.columnFields = [...this.columnFields, ...newColumns];
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-table {
  @include table-design(2);
}
.insurance-not-found {
  p {
    font-family: $font-family-portal;
    font-size: 1rem;
    margin: 0px;
  }
  &__icon {
    font-size: 50px;
  }
}
::v-deep .cell {
  word-break: normal;
}
::v-deep .el-table {
  background: transparent;
  tr {
    background-color: white;
    cursor: pointer;
  }
  &__row {
    .el-table__cell {
      text-align: center;
      border-bottom: 1px solid $color-border-container;
    }
  }
}
::v-deep {
  .el-table .el-loading-mask {
    border-radius: 8px;
    background-color: rgba(239, 239, 239, 0.7);
  }
  .cell {
    word-break: normal;
  }
  :deep(.el-table__header-wrapper) {
    background-color: #efefef;
    border: 2px solid $color-border-container;
    border-radius: 15px;
    font-weight: 700;
    tr,
    th {
      background: none;
    }
    th {
      position: relative;
      margin: 5px 0px;
      font-weight: 600;
      font-size: 11px;
      color: $color-primary-company;
      text-align: center;
      & .cell {
        line-height: normal;
        word-break: normal;
        background-color: #efefef;
      }
    }
  }
}
.quoted-color {
  color: #3a3a3a;
}
.booked-color {
  color: #00ccff;
}
.issued-color {
  color: #336600;
}
</style>
