<template>
  <div class="drag-drop-files">
    <div class="file-container">
      <el-upload
        class="upload-demo"
        drag
        action="#"
        :auto-upload="false"
        :on-change="handlePreview"
      >
        <template v-if="value">
          <div class="document-name">
            <i class="ion ion-folder-outline"></i>
            <span class="document-name__text">{{ value.name }}</span>
          </div>
        </template>
        <template v-else>
          <i class="el-icon-document"></i>
          <div class="el-upload__text" v-if="screenWidth >= 800">
            {{ $t("claimOrDispute.dropDocuments") }}
            <em>{{ $t("claimOrDispute.clickUpload") }}</em>
          </div>
          <div class="el-upload__text" v-else>
            <em>{{ $t("claimOrDispute.dropDocumentsMobile") }}</em>
          </div>
        </template>
      </el-upload>
    </div>
  </div>
</template>

<script>
import onReSize from "../Extend/onResize";

export default {
  name: "DragAndDropFiles",
  mixins: [onReSize],
  props: {
    value: Object,
    selectDocuments: Array,
    limitSize: Number,
    allowedExtensions: Array,
    isCargoCare: Boolean,
  },
  data() {
    return {
      uploadedFile: null,
    };
  },
  beforeDestroy() {
    this.$emit("input", null);
  },
  methods: {
    handlePreview(file) {
      const fileExtension = file.raw.name.split(".").pop();
      if (!this.allowedExtensions.includes(fileExtension) && !this.isCargoCare) {
        this.$message.error("File must be XLS or XLSX format!");
        return;
      }
      if (!this.allowedExtensions.includes(fileExtension) && this.isCargoCare) {
        this.$message.error("File must be PDF format!");
        return;
      }
      this.$emit("input", file);
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-drop-files {
  width: 100%;
}

.document-name {
  font-size: 18px;
  font-weight: 500;
  color: $color-primary-company;
  &__text {
    margin-left: 5px;
  }
}

///****File styles****\\\\
::v-deep {
  .el-upload {
    display: flex;
    @media (max-width: 800px) {
      justify-content: flex-end;
    }
  }
  .el-upload__text {
    color: $color-primary-company;
    em {
      color: $color-border-container;
      font-weight: 600;
    }
  }
  .el-upload-dragger {
    border-radius: 10px;
    height: 100px;
    width: 100%;
    border: 1px dashed $color-border-container;
  }
  .el-icon-document:before {
    font-size: 40px;
    color: $color-border-container;
  }
  .el-icon-document {
    margin: 10px 0;
  }
  .el-icon-close:before {
    color: $color-primary-company;
    font-weight: 600;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  .el-upload-list__item {
    display: none;
  }
}
</style>
