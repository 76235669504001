<template>
  <div class="section">
    <div class="section__popover" v-if="popoverData.isPopover">
      <PopupComponent
        :title="popoverData.title"
        :content="popoverData.content"
        :target="popoverData.target"
      ></PopupComponent>
    </div>
    <div class="section__title">
      <label
        :class="{
          'color-secondary-title': colorSecondaryTitle,
        }"
      >
        {{ title }}<span v-if="mandatory" class="section__mandatory">*</span>
      </label>
    </div>
  </div>
</template>
<script>
import PopupComponent from "./PopupComponent.vue";

export default {
  name: "SectionTitle",
  components: {
    PopupComponent,
  },
  props: {
    colorSecondaryTitle: {
      type: Boolean,
      default: false,
    },
    popoverData: {
      type: Object,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
  &__title {
    display: flex;
    text-align: left;
    align-items: flex-start;
    gap: 5px;
    font-size: 15px;
    font-weight: bold;
  }
  &__popover {
    display: flex;
    text-align: right;
    align-items: flex-start;
  }
  &__mandatory {
    margin-left: 3px;
    color: $color-error;
  }
}

.color-secondary-title {
  color: $color-secondary-button-text;
}
</style>
