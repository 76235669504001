<template>
  <div class="trigger-buttons-container">
    <button
      :disabled="scope.status === 'Quoted' || scope.status === 'Booked'"
      class="trigger-button trigger-button--show"
      v-b-popover.hover.top="'Preview'"
      @click.stop="showDocument()"
    >
      <i class="ion ion-eye-outline"></i>
    </button>
    <button
      :disabled="scope.status === 'Quoted' || scope.status === 'Booked'"
      class="trigger-button trigger-button--share"
      v-b-popover.hover.top="'Share'"
      @click.stop="shareDocument()"
    >
      <i class="ion ion-share-social-outline"></i>
    </button>
    <button
      v-if="tokenInfo.role === 'CargoCare Admin' || tokenInfo.role === 'GLT Admin'"
      :disabled="scope.status === 'Quoted'"
      class="trigger-button trigger-button--send"
      v-b-popover.hover.top="'Upload'"
      @click.stop="sendDocument()"
    >
      <i class="ion ion-share-outline"></i>
    </button>
    <button
      :disabled="scope.status === 'Quoted' || scope.status === 'Booked'"
      class="trigger-button trigger-button--download"
      v-b-popover.hover.top="'Download'"
      @click.stop="downloadDocuments()"
    >
      <i class="ion ion-download-outline"></i>
    </button>
    <div class="document-viewer-container" v-if="showViewerDocument">
      <InsuranceDocumentViewer
        :document="certificateDocument"
        @shareDocument="shareDocument"
        @handlerDocumentViewer="handlerDocumentViewer"
        @enableScroll="enableScroll"
      />
    </div>
    <CustomModal
      :centered="true"
      :no-close-on-backdrop="true"
      size="md"
      modalTitle="Share document"
      v-model="shareDocumentModal"
    >
      <template #modal-content>
        <ShareDocument
          :insuranceId="scope.id"
          :documentInformation="certificateDocument"
          :documentType="'certificate'"
          :isCargoCare="true"
          @handleCloseModal="handleCloseModal"
          ref="shareDocumentModal"
        />
      </template>
    </CustomModal>
    <CustomModal
      :centered="true"
      :no-close-on-backdrop="true"
      size="md"
      modalTitle="Certificate"
      v-model="certificateModal"
    >
      <template #modal-content>
        <SendCertificate
          :insuranceId="scope.id"
          :document="certificateDocument"
          @handlerSendDocumentModal="handlerSendDocumentModal"
        />
      </template>
    </CustomModal>
  </div>
</template>

<script>
import downloadDocument from "@/utils/DownloadDocuments";
import CustomModal from "../../../../components/CustomModal.vue";
import InsuranceDocumentViewer from "./InsuranceDocumentViewer.vue";
import SendCertificate from "./SendCertificate.vue";
import ShareDocument from "../../../../components/ShareDocument.vue";

export default {
  name: "ToolsButtons",
  components: {
    CustomModal,
    SendCertificate,
    ShareDocument,
    InsuranceDocumentViewer,
  },
  props: {
    scope: Object,
  },
  data() {
    return {
      certificateModal: false,
      showViewerDocument: false,
      certificateDocument: null,
      shareDocumentModal: false,
      tokenInfo: null,
    };
  },
  created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
  },
  methods: {
    async downloadDocuments() {
      const documents = await this.getInsuranceDocuments(this.scope.id);
      const certificateDocument = this.findCertificateDocument(documents);
      if (certificateDocument) {
        downloadDocument(certificateDocument.url, certificateDocument.name);
      } else {
        this.notifyNoDocumentsFind();
      }
    },
    findCertificateDocument(documents) {
      return documents.find((doc) => doc.name.startsWith("certificate/"));
    },
    async showDocument() {
      const response = await this.getInsuranceDocuments(this.scope.id);
      // eslint-disable-next-line max-len
      this.certificateDocument = this.findCertificateDocument(response);
      if (this.certificateDocument) {
        this.handlerDocumentViewer(true);
        this.disableScroll();
      } else {
        this.notifyNoDocumentsFind();
      }
    },
    handlerDocumentViewer(data) {
      this.showViewerDocument = data;
    },
    async getInsuranceDocuments(id) {
      const response = await this.$store.dispatch("insurance/generateDocuments", id);
      return response;
    },
    async shareDocument() {
      this.handlerDocumentViewer(false);
      const response = await this.getInsuranceDocuments(this.scope.id);
      // eslint-disable-next-line max-len
      this.certificateDocument = this.findCertificateDocument(response);
      if (this.certificateDocument) {
        this.shareDocumentModal = true;
      } else {
        this.notifyNoDocumentsFind();
      }
    },
    async sendDocument() {
      if (this.tokenInfo.role === "CargoCare Admin" || this.tokenInfo.role === "GLT Admin") {
        const response = await this.getInsuranceDocuments(this.scope.id);
        // eslint-disable-next-line max-len
        this.certificateDocument = this.findCertificateDocument(response);
        this.handlerSendDocumentModal(true);
      }
    },
    notifyNoDocumentsFind() {
      this.$notify({
        title: "The insurance does not have any certificate document",
      });
    },
    handlerSendDocumentModal(data) {
      this.certificateModal = data;
    },
    handleCloseModal() {
      this.shareDocumentModal = false;
    },
    disableScroll() {
      document.body.style.overflow = "hidden";
      document.body.style.userSelect = "none";
    },
    enableScroll() {
      document.body.style.overflow = "auto";
      document.body.style.userSelect = "auto";
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger-buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 5px;
}
.trigger-button {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bolder;
  padding: 0px;
  i {
    font-size: 18px;
  }
}
</style>
