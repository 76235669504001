<template>
  <div class="export-table-button">
    <button class="button-container" @click="exportData()" title="Download excel">
      <i v-if="!loadingExport" class="el-icon-download"></i>
      <b-spinner class="spinner" v-if="loadingExport" variant="primary" small></b-spinner>
    </button>
  </div>
</template>

<script>
import XLSX from "xlsx/xlsx";

export default {
  name: "ExportTableButton",
  props: {
    filters: Object,
    filteredData: Array
  },
  data() {
    return {
      columnsExport: [
        { label: "Quote Number", field: "load.shp" },
        { label: "Conveyance", field: "conveyance" },
        { label: "Status", field: "status" },
        { label: "Origin", field: "origin" },
        { label: "Destination", field: "destination" },
        { label: "Commodity", field: "commodity" },
        { label: "Certificate Number", field: "certificate_id" },
        { label: "Date of depurate", field: "sailing_date", dataFormat: this.dateFormat },
        { label: "Insurance Value", field: "insured_value", dataFormat: this.priceFormat },
      ],
      loadingExport: false,
    };
  },
  methods: {
    async exportData() {
      this.loadingExport = true;
      this.$emit('handlingLimitItemsTable', { skip: 0, limit: 200 });
      this.$emit("getInsurancesList", this.filters, true);
      this.exportExcel(this.filteredData);
      this.$emit('handlingLimitItemsTable', { skip: 0, limit: 25 });
      this.loadingExport = false;
    },
    priceFormat(value) {
      return this.$options.filters.priceToUsd(value);
    },
    dateFormat(value) {
      return this.$options.filters.dateFormat(value);
    },
    formatLocationText(city, state, zipcode) {
      return `${city}, ${state} ${zipcode}`;
    },
    exportExcel(data) {
      const createXLSLFormatObj = [];
      const newXlsHeader = [];
      const vm = this;

      // Mapear las etiquetas de columna
      vm.columnsExport.map((column) => newXlsHeader.push(column.label));
      createXLSLFormatObj.push(newXlsHeader);

      // Mapear los datos
      // eslint-disable-next-line array-callback-return
      data.map((value) => {
        const innerRowData = [];
        vm.columnsExport.map((val) => {
          let fieldValue = value;
          const fieldProperties = val.field.split(".");
          // eslint-disable-next-line no-restricted-syntax
          for (const prop of fieldProperties) {
            // Obtener el valor del campo
            fieldValue = fieldValue[prop];
          }
          // Aplicar formato si es necesario
          if (val.dataFormat && typeof val.dataFormat === "function") {
            return innerRowData.push(val.dataFormat(fieldValue));
          }

          // Caso de los locations
          if (val.field === "origin") {
            fieldValue = this.formatLocationText(
              value.origin_city,
              value.origin_state,
              value.origin_zip_code
            );
          }
          if (val.field === "destination") {
            fieldValue = this.formatLocationText(
              value.destination_city,
              value.destination_state,
              value.destination_zip_code
            );
          }

          // Agregar el valor al array
          return innerRowData.push(fieldValue);
        });
        // Agregar la fila al array principal
        createXLSLFormatObj.push(innerRowData);
      });
      // Crear el archivo Excel
      const fileName = "My Insurances.xlsx";
      const wsName = "sheetname";
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, wsName);
      XLSX.writeFile(wb, fileName);
    },
    getNestedValue(object, string) {
      const string1 = string.replace(/\[(\w+)\]/g, ".$1");
      const string2 = string1.replace(/^\./, "");
      const a = string2.split(".");
      let objectCopy = object;
      a.forEach((k) => {
        if (k in objectCopy) {
          objectCopy = objectCopy[k];
        }
      });
      return object;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  border-radius: 5px;
  border: 2px solid $color-border-container;
  color: $color-primary-company;
  background-color: #efefef;
  padding: 0.1rem 0.4rem;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    border-color: $color-border-container;
    background-color: rgba(0, 185, 204, 0.0588235294);
  }
}
</style>
