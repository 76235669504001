<template>
  <div class="filter-location-container">
    <div class="container-inputs__inputs ml-1">
      <span :class="filterInformation.origin ? 'active' : ''">
        <i class="el-icon-location mr-1"></i>
        Origin
      </span>
      <div class="input-container mt-1">
        <input
          v-model="filterInformation.origin"
          id="origin"
          type="text"
          class="input-information"
        />
      </div>
    </div>
    <div class="container-inputs__inputs ml-1">
      <span :class="filterInformation.destination ? 'active' : ''">
        <i class="el-icon-add-location mr-1"></i>
        Destination
      </span>
      <div class="input-container mt-1">
        <input
          v-model="filterInformation.destination"
          id="destination"
          type="text"
          class="input-information"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterLocation",
  props: {
    filter: Object,
  },
  data() {
    return {
      filterInformation: {
        origin: "",
        destination: "",
      },
    };
  },
  created() {
    if (this.filter) {
      this.filterInformation = { ...this.filter };
    }
  },
  watch: {
    filterInformation: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("getLocationInformation", this.filterInformation);
      },
    },
  },
  methods: {
    resetFilters() {
      this.filterInformation = {
        origin: "",
        destination: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-location-container {
  background-color: #efefef;
}
.container-inputs {
  &__inputs {
    flex-direction: row;
    margin-bottom: 5px;
    span {
      display: flex;
      align-items: center;
      color: #606266;
      font-size: 11px;
    }
    i {
      font-size: 13px;
    }
  }
  &__titles {
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
  }
  &__titles-active {
    font-size: 11px;
    font-family: $font-family-portal;
    color: $color-border-container;
  }
}
.input-container {
  @include input-information;
  border: 1px solid #fff;
  color: $color-primary-company;
  padding: 7px 0px 5px 5px;
  width: 95%;
  height: 25px;
  box-shadow: none;
  margin: 0px;
  font-family: $font-family-portal;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}
.input-information {
  @include input-password;
  width: 100%;
  font-size: 11px;
  color: #606266;
}
.active {
  color: $color-border-container !important;
}
</style>
